.swiper-slide {
  &.anw-nav-item {
    width: auto;
  }
}

@media (min-width: 1025px) {
  .swiper-wrapper.nav {
    flex-wrap: wrap;
    transform: translate3d(0, 0, 0) !important;
  }
}
